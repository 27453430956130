var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',{staticClass:"mt-3"},[_c('CCardBody',[_c('div',{staticClass:"d-flex flex-wrap"},[_c('TInputRadio',{attrs:{"list":_vm.filter_options,"value":_vm.temp.filter_radio,"label":"Option","custom":""},on:{"update:value":[function($event){return _vm.$set(_vm.temp, "filter_radio", $event)},_vm.triggerSearch]}})],1)])],1),_c('TTableAdvance',{attrs:{"items":_vm.list,"fields":_vm.fields,"store":"warehouse.box_containers","resource":""},on:{"click-clear-filter":_vm.clearFilter},scopedSlots:_vm._u([{key:"_",fn:function(ref){
var index = ref.index;
return [_c('td',[_c('TMessage',{attrs:{"content":index + 1,"alignment":"center","noTranslate":""}})],1)]}},{key:"id",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessage',{attrs:{"content":item.id,"noTranslate":""}})],1)]}},{key:"container",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TLink',{attrs:{"content":item.container ? item.container.name : item.container_id,"to":_vm.lodash.getReferenceLink('invoice', item.container_id)}})],1)]}},{key:"quantity_in_lading_bill",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageNumber',{attrs:{"value":item.quantity_in_lading_bill}})],1)]}},{key:"quantity_in_picker",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageNumber',{attrs:{"value":item.quantity_in_picker}})],1)]}},{key:"quantity_out_picker",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageNumber',{attrs:{"value":item.quantity_out_picker}})],1)]}},{key:"box_id-filter",fn:function(){return [_c('TInputText',{attrs:{"placeholder":"SKU Id","value":_vm.filter.box_id},on:{"update:value":[function($event){return _vm.$set(_vm.filter, "box_id", $event)},_vm.triggerSearch]}})]},proxy:true},{key:"container-filter",fn:function(){return [_c('SSelectInvoice',{staticClass:"flex-grow-1",attrs:{"prependAll":"","value":_vm.filter.container_id},on:{"update:value":function($event){return _vm.$set(_vm.filter, "container_id", $event)},"change":_vm.triggerSearch}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }